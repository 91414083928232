<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import operationalProblemFormatters from '@src/mixins/operationalProblemFormatters'

const i18nKey = 'BUSINESS_UNITS'

export default {
    mixins: [
        vuetableFormatter,
        operationalProblemFormatters
    ],
    data() {
        return {
            businessUnitOperationProblemFields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.business_unit'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'contract_value',
                    label: this.getI18n(i18nKey, 'TITLES.contract_value'),
                    sortable: true,
                    validator: this.formatPositiveCurrency,
                },
                {
                    key: 'contract_time',
                    label: this.getI18n(i18nKey, 'TITLES.contract_time'),
                    sortable: true,
                    validator: this.formatPositiveNumber,
                },
                {
                    key: 'point_of_sales_count',
                    label: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    sortable: true,
                    validator: this.formatPositiveCount,
                },
                {
                    key: 'users_count',
                    label: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    }),
                    sortable: true,
                    validator: this.formatPositiveCount,
                },
            ],
        }
    },
}
</script>
