<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            operationalProblemFields: [
                {
                    name: 'created_at',
                    title: this.getI18n('COMMON', 'created_at'),
                },
                {
                    name: 'point_of_sales',
                    title: this.getI18nModified({
                            prefix: 'POS',
                            suffix: 'TITLES.pos',
                            modifier: 2,
                        }),
                },
                {
                    name: 'business_units',
                    title: this.getI18nModified({
                            prefix: 'BUSINESS_UNITS',
                            suffix: 'TITLES.business_unit',
                            modifier: 2,
                        }),
                },
                {
                    name: 'users',
                    title: this.getI18nModified({
                            prefix: 'USERS',
                            suffix: 'TITLES.user',
                            modifier: 2,
                        }),
                },
                {
                    name: 'products',
                    title: this.getI18nModified({
                            prefix: 'PRODUCTS',
                            suffix: 'TITLES.product',
                            modifier: 2,
                        }),
                },
                {
                    name: 'work_schedules',
                    title: this.getI18nModified({
                            prefix: 'WORKSCHEDULES',
                            suffix: 'TITLES.work_schedule',
                            modifier: 2,
                        }),
                },
            ],
        }
    },
}
</script>
