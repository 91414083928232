<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'PRODUCTS'
const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            productOperationProblemFields: [
                {
                    key: 'reference',
                    label: this.getI18n(i18nKey, 'FIELDS.reference'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'brand.name',
                    label: this.getI18n('BRANDS', 'TITLES.brand'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'category.name',
                    label: this.getI18n('PRODUCT_CATEGORIES', 'TITLES.product_category'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'min_price',
                    label: this.getI18n(i18nKey, 'FIELDS.min_price'),
                    sortable: true,
                    formatter: this.formatPositiveCurrency,
                },
                {
                    key: 'max_price',
                    label: this.getI18n(i18nKey, 'FIELDS.max_price'),
                    sortable: true,
                    formatter: this.formatPositiveCurrency,
                },
                {
                    key: 'thumbnail_file_id',
                    label: this.getI18n(i18nCommon, 'photo'),
                    sortable: true,
                    validator: this.formatEmptyPhoto,
                },
            ],
        }
    },
}
</script>
