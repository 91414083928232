<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            posOperationProblemFields: [
                {
                    key: 'external_code',
                    label: this.getI18n(i18nCommon, 'external_code'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.pos'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'long_name',
                    label: this.getI18n(i18nCommon, 'address'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'location',
                    label: `${this.getI18n(i18nCommon, 'latitude')} ${this.getI18n(i18nCommon, 'and').toLowerCase()} ${this.getI18n(i18nCommon, 'longitude')}`,
                    sortable: true,
                    validator: this.formatLocation,
                },
                {
                    key: 'channel.name',
                    label: this.getI18n('POS', 'TITLES.channel'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'category.name',
                    label: this.getI18n('POS', 'TITLES.category'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'chain.name',
                    label: this.getI18n('POS', 'TITLES.chain'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'regional.name',
                    label: this.getI18n('POS', 'TITLES.regional'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'flag.name',
                    label: this.getI18n('POS', 'TITLES.flag'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'business_units_count',
                    label: this.getI18nModified({
                        prefix: 'BUSINESS_UNITS',
                        suffix: 'TITLES.business_unit',
                        modifier: 2,
                    }),
                    sortable: true,
                    validator: this.formatPositiveCount,
                },
            ],
        }
    },
}
</script>
