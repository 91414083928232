<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            workScheduleOperationProblemFields: [
                {
                    key: 'point_of_sale.name',
                    label: this.getI18n(i18nCommon, 'pos'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'mix.name',
                    label: this.getI18n('MIXES', 'TITLES.mix'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'user.name',
                    label: this.getI18n('USERS', 'TITLES.user'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'human_readable_frequency',
                    label: this.getI18n(i18nCommon, 'frequency'),
                    sortable: true,
                    validator: this.formatEmptyFrequency,
                },
                {
                    key: 'start',
                    label: this.getI18n(i18nCommon, 'start_date'),
                    sortable: true,
                    validator: this.formatEmptyDate,
                },
                {
                    key: 'end',
                    label: this.getI18n(i18nCommon, 'end_date'),
                    sortable: true,
                    validator: this.formatEmptyDate,
                },
            ],
        }
    },
}
</script>
