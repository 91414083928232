<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            userOperationProblemFields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'email',
                    label: this.getI18n(i18nCommon, 'email'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'role.0.alias',
                    label: this.getI18n(i18nCommon, 'role'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'workday.name',
                    label: this.getI18n(i18nCommon, 'workday'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'long_name',
                    label: this.getI18n(i18nCommon, 'address'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
                {
                    key: 'timezone',
                    label: this.getI18n(i18nCommon, 'timezone'),
                    sortable: true,
                    validator: this.formatEmptyTimezone,
                },
                {
                    key: 'app_version',
                    label: this.getI18n(i18nCommon, 'app_version'),
                    sortable: true,
                    validator: this.formatEmptyText,
                },
            ],
        }
    },
}
</script>
