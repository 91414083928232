<template>
    <form-modal ref="formModal" :title="titleModal" size="xl">
        <template v-slot:form>
            <form>
                <div class="row">
                    <div class="col-sm-6 form-inline">
                        <filter-bar v-model="filterText"></filter-bar>
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <slot></slot>
                        </div>
                    </div>
                </div>
                <b-table
                    hover
                    :fields="fields"
                    :items="itemsComp"
                    :filter="filterText"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <template v-for="field of fields" v-slot:[`cell(${field.key})`]="data">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div :key="field.key" v-html="validator(data)"></div>
                    </template>
                </b-table>
                <b-pagination
                v-model="currentPage"
                :total-rows="itemsComp.length"
                :per-page="perPage"
                align="right"
                variant="danger"
                />
            </form>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import FilterBar from '@src/components/FilterBar'
import businessUnitOperationProblemFields from '@src/fields/operationalProblem/businessUnitOperationProblemFields'
import posOperationProblemFields from '@src/fields/operationalProblem/posOperationProblemFields'
import productOperationProblemFields from '@src/fields/operationalProblem/productOperationProblemFields'
import userOperationProblemFields from '@src/fields/operationalProblem/userOperationProblemFields'
import workScheduleOperationProblemFields from '@src/fields/operationalProblem/workScheduleOperationProblemFields'

const i18nCommon = 'COMMON'
const i18nKey = 'OPERATIONAL_PROBLEM'

export default {
    components: {
        FormModal,
        FilterBar,
    },
    mixins: [
        businessUnitOperationProblemFields,
        posOperationProblemFields,
        productOperationProblemFields,
        userOperationProblemFields,
        workScheduleOperationProblemFields,
    ],
    props: {
        modal: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            filterText: null,
            titleModal: '',
            itemsModal: [],
            currentPage: 1,
            perPage: 10,
            fields: []
        }
    },
    computed: {
        itemsComp: {
            get() {
                return this.itemsModal
            },
            set(value) {
                this.$emit('itemsModal', value)
            },
        },
        i18nPointOfSales() {
            return this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
            })
        },
        i18nBusinessUnits() {
            return this.getI18nModified({
                        prefix: 'BUSINESS_UNITS',
                        suffix: 'TITLES.business_unit',
                        modifier: 2,
            })
        },
        i18nUsers() {
            return this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
            })
        },
        i18nProducts() {
            return this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
            })
        },
        i18nWorkSchedule() {
            return this.getI18nModified({
                        prefix: 'WORKSCHEDULES',
                        suffix: 'TITLES.work_schedule',
                        modifier: 2,
            })
        },
    },
    methods: {
        hideModal() {
            this.$refs.formModal.hide()
        },
        showModal(type) {
            this.gridModal = this.modal
            this.titleModal = `${this.getI18n(i18nKey, 'TITLES.operational_problem')}: `
            if (type === 'point_of_sales') {
                this.titleModal += this.i18nPointOfSales
                this.itemsModal = this.gridModal.point_of_sales
                this.fields = this.posOperationProblemFields
            } else if (type === 'business_units') {
                this.titleModal += this.i18nBusinessUnits
                this.itemsModal = this.gridModal.business_units
                this.fields = this.businessUnitOperationProblemFields
            } else if (type === 'users') {
                this.titleModal += this.i18nUsers
                this.itemsModal = this.gridModal.users
                this.fields = this.userOperationProblemFields
            } else if (type === 'products') {
                this.titleModal += this.i18nProducts
                this.itemsModal = this.gridModal.products
                this.fields = this.productOperationProblemFields
            } else if (type === 'work_schedules') {
                this.titleModal += this.i18nWorkSchedule
                this.itemsModal = this.gridModal.work_schedules
                this.fields = this.workScheduleOperationProblemFields
            }

            this.$refs.formModal.show()
        },
        validator(data) {
            if (data && data.field && data.field.validator) {
                return data.field.validator(data.value)
            }
            return data.value
        }
    },
}
</script>
