<script>
import getI18n from '@src/mixins/getI18n'
import vuetableFormatter from '@src/mixins/vuetableFormatters'


export default {
    mixins: [getI18n, vuetableFormatter],
    computed: {
        i18nUninformedIcon() {
            return `
                <i class="fe-alert-triangle icon-red" title="${this.getI18n('COMMON', 'uninformed')}" />
            `
        },
        // <span class="text-danger">${this.getI18n('COMMON', 'uninformed')}</span>
    },
    methods: {
        formatEmptyDate(value) {
            return value ? this.formatDate(value) : this.i18nUninformedIcon
        },
        formatEmptyFrequency(value) {
            return this.formatFrequency(value) || this.i18nUninformedIcon
        },
        formatEmptyPhoto(value) {
            return value ? '<i class="fe-camera icon-blue"/>' : `<i class="fe-camera-off icon-red"/>`
        },
        formatEmptyTimezone(value) {
            return this.formatTimezone(value) || this.i18nUninformedIcon
        },
        formatEmptyText(value) {
            return value || this.i18nUninformedIcon
        },
        formatLocation(value) {
            if (!value || value.coordinates.length !== 2 || value.coordinates[0] === 0 || value.coordinates[1] === 0) {
                return this.i18nUninformedIcon
            }

            return `${value.coordinates[0]}<br>${value.coordinates[1]}`
        },
        formatPositiveCurrency(value) {
            return value && value > 0 ? this.getI18nCurrency(value) : `<span class="text-danger">${this.getI18nCurrency(value)}</span>`
        },
        formatPositiveCount(value) {
            return value > 0 ? this.padLeft(value) : `<span class="text-danger">${this.padLeft(value)}</span>`
        },
        formatPositiveNumber(value) {
            return value && value > 0 ? value : `<span class="text-danger">${value || 0}</span>`
        }
  },
}
</script>
