<script>
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import PageHeader from '@components/PageHeader'
import OperationalProblem from '@src/services/OperationalProblem'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import operationalProblemFields from '@src/fields/operationalProblemFields'
import filterVuetable from '@src/mixins/filterVuetable'
import CountTableButton from '@/src/components/CountTableButton.vue'
import operationalProblemModal from '@src/router/views/operationalProblem/operationalProblemModal.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'OPERATIONAL_PROBLEM'

export default {
    components: {
        Layout,
        Breadcrumb,
        PageHeader,
        ApiModeVuetable,
        CountTableButton,
        operationalProblemModal,
    },
    mixins: [
        swalFeedbackVue,
        operationalProblemFields,
        filterVuetable
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            perPage: 10,
            modal: {
                id: 0,
                created_at: '',
                point_of_sales: [],
                business_units: [],
                users: [],
                products: [],
                work_schedules: [],
            },
            additionalParameters: {},
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'report',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.operational_problem',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nInfoRefreshReport() {
            return this.getI18n(i18nKey, 'MESSAGES.info_refresh_report')
        },
        i18nOperationalProblem() {
            return this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.operational_problem',
                        modifier: 2,
            })
        },
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({})
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        fetch(url, params) {
            return OperationalProblem.fetchVuetable(url, params)
        },

        replaceModalData(data) {
            this.modal = JSON.parse(JSON.stringify(data));
        },

        openModalPos(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.operationalProblemModal.showModal('point_of_sales'))
        },

        openModalBusinessUnits(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.operationalProblemModal.showModal('business_units'))
        },

        openModalProducts(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.operationalProblemModal.showModal('products'))
        },

        openModalUsers(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.operationalProblemModal.showModal('users'))
        },

        openModalWorkSchedules(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.operationalProblemModal.showModal('work_schedules'))
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-12 ml-1">
                    <b-alert show variant="info">
                        <i class="fe-info icon-blue mr-1" />
                        {{ i18nInfoRefreshReport }}.
                    </b-alert>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nOperationalProblem" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="operationalProblemFields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="point_of_sales" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.point_of_sales.length"
                                @onClick="openModalPos(props.rowData)"
                            />
                        </template>
                        <template slot="business_units" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.business_units.length"
                                @onClick="openModalBusinessUnits(props.rowData)"
                            />
                        </template>
                        <template slot="products" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.products.length"
                                @onClick="openModalProducts(props.rowData)"
                            />
                        </template>
                        <template slot="users" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.users.length"
                                @onClick="openModalUsers(props.rowData)"
                            />
                        </template>
                        <template slot="work_schedules" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.work_schedules.length"
                                @onClick="openModalWorkSchedules(props.rowData)"
                            />
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <operational-problem-modal
            ref="operationalProblemModal"
            :modal="modal"
        />
    </Layout>
</template>
